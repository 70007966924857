import React, { useEffect, useState, startTransition } from 'react'
import { useAtomValue, useSetAtom } from 'jotai/index'
import { AppSelect, ESelectVariant, GridTable, GridTableHead, GridTableItem, MediumText, RegularText } from 'src/components'

import { updateRestStatsFiltersAtom, getRestStatsAtom } from '../../atoms/restaraunts/restAtom'
import { DateFilterAuto } from '../../components/DateFilterAuto'
import { datePeriodOptions, EDateRangeOptions } from '../../utils'
import { RestStatsResponse } from '../../services'
import { DateFilterSelect } from '../../components/DateFilterSelect'

const THEAD = [
    'Завершенные заказы',
    'Активные заказы',
    'В избранном',
    'Среднее кол-во заказов',
    'Средняя стоимость заказа',
    'Популярность среди других ресторанов',
    'Среднее время приготовления',
    'Минимальное время приготовления',
    'Максимальное время приготовления',
]

type DateType = {
    dateFrom: string
    dateTo: string
}

type SelectOption = { value: string; label: string }

export const ModalEditRestStat = (): JSX.Element => {
    const { data } = useAtomValue(getRestStatsAtom)
    const stat = data as RestStatsResponse

    const updateFilters = useSetAtom(updateRestStatsFiltersAtom)
    const [dates, setDates] = useState<DateType | object>({})
    const [orderStatus, setOrderStatus] = useState<SelectOption | null>()

    const setDate = (fieldName: 'dateFrom' | 'dateTo') => (val: string) => {
        setDates(prev => ({ ...prev, [fieldName]: val }))
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            startTransition(() => {
                updateFilters({ ...dates, orderStatus: orderStatus?.value })
            })
        }, 300)

        return () => clearTimeout(timer)
    }, [dates, orderStatus])

    const handleOrderStatusChange = (selectedOption: SelectOption | null) => {
        setOrderStatus(selectedOption)
    }

    return (
        <div className='py-9 px-4'>
            <div>
                <DateFilterSelect
                    dateFrom={(dates as DateType)?.dateFrom || ''}
                    dateTo={(dates as DateType)?.dateTo || ''}
                    setDateFrom={setDate('dateFrom')}
                    setDateTo={setDate('dateTo')}
                    selectProps={{ options: datePeriodOptions }}
                    rightElement={
                        <AppSelect
                            className='ml-6'
                            placeholder='Все заказы'
                            selectVariant={ESelectVariant.QUARTERY}
                            options={[
                                { value: 'onlyOpen', label: 'Только открытые' },
                                { value: 'onlyClosed', label: 'Только закрытые' },
                            ]}
                            value={orderStatus}
                            onChange={handleOrderStatusChange}
                            isClearable
                        />
                    }
                />
                <div className='py-2'>
                    <GridTable
                        theads={THEAD.map(head => (
                            <GridTableHead className='text-nowrap whitespace-nowrap p-4 bg-aqua-400' key={head} title={head} />
                        ))}>
                        {Object.keys(data).length ? (
                            <tr>
                                <GridTableItem child={stat.done} />
                                <GridTableItem child={stat.going} />
                                <GridTableItem child={stat.favoriteCount || 0} />
                                <GridTableItem child={stat.averageOrderCount || '-'} />
                                <GridTableItem child={stat.averageOrderPrice ? `${stat.averageOrderPrice.toFixed(2)}` : '-'} />
                                {/*<GridTableItem child="Нет данных" />*/}
                                <GridTableItem child={stat.popularityPercentage || '-'} />
                                <GridTableItem child={stat.averageCookingTime ? `${stat.averageCookingTime.toFixed(2)}` : '-'} />
                                <GridTableItem child={stat.minCookingTime || '-'} />
                                <GridTableItem child={stat.maxCookingTime || '-'} />
                            </tr>
                        ) : (
                            <RegularText>Нет данных</RegularText>
                        )}
                    </GridTable>
                </div>
            </div>
        </div>
    )
}
