import React, { FC } from 'react'
import { GridTable, GridTableHead, GridTableItem, MediumText, Spinner, TextInput } from '../../../../components'
import { DateFilterSelect } from '../../../../components/DateFilterSelect'
import { datePeriodOptions } from '../../../../utils'
import styles from '../../../../features/Orders/styles.module.scss'
import { DateType, IReport } from '../../index'

interface IProps {
    data: IReport[]
    loading: boolean
    dates: DateType | object
    setDate: (date: 'dateFrom' | 'dateTo') => (val: string) => void
}

export const ReportRest: FC<IProps> = ({ dates, setDate, data, loading }) => {
    return (
        <div>
            <div className={'flex gap-4'}>
                <DateFilterSelect
                    dateFrom={(dates as DateType)?.dateFrom || ''}
                    dateTo={(dates as DateType)?.dateTo || ''}
                    setDateFrom={setDate('dateFrom')}
                    setDateTo={setDate('dateTo')}
                    selectProps={{ options: datePeriodOptions }}
                />
            </div>

            {loading ? (
                <Spinner />
            ) : (
                <div className='mt-4'>
                    <GridTable
                        className={styles.table}
                        // style={{ height: window.innerHeight / (window.innerWidth / 2018) - 148 + 'px' }}
                        theads={[
                            'Имя',
                            'Тип заказа',
                            'Новый заказ - Заказ принят',
                            'Заказ принят - заказ готов',
                            'Заказ готов - Заказ отправлен',
                            'Заказ отправлен - Заказ завершен',
                            'Время заказа',
                        ].map(el => (
                            <GridTableHead key={el} title={el} />
                        ))}>
                        {data.map(i => (
                            <tr>
                                <GridTableItem child={i?.title} style={{ borderBottom: '1px solid black' }} />
                                <GridTableItem
                                    style={{ padding: 0, borderBottom: '1px solid black' }}
                                    child={i.stats.map((item, index) =>
                                        item.type === 'Pickup' ? (
                                            <div style={index === i?.stats?.length - 1 ? {} : { borderBottom: '1px solid var(--aqua700)' }}>
                                                самовывоз
                                            </div>
                                        ) : (
                                            <div style={index === i?.stats?.length - 1 ? {} : { borderBottom: '1px solid var(--aqua700)' }}>
                                                доставка
                                            </div>
                                        ),
                                    )}
                                />
                                <GridTableItem
                                    style={{ padding: 0, borderBottom: '1px solid black' }}
                                    child={i?.stats.map((item, index) => (
                                        <div style={index === i?.stats?.length - 1 ? {} : { borderBottom: '1px solid var(--aqua700)' }}>
                                            {item.stages?.new || '0'}
                                        </div>
                                    ))}
                                />
                                <GridTableItem
                                    style={{ padding: 0, borderBottom: '1px solid black' }}
                                    child={i?.stats.map((item, index) => (
                                        <div style={index === i?.stats?.length - 1 ? {} : { borderBottom: '1px solid var(--aqua700)' }}>
                                            {item.stages?.cooking || '0'}
                                        </div>
                                    ))}
                                />
                                <GridTableItem
                                    style={{ padding: 0, borderBottom: '1px solid black' }}
                                    child={i?.stats.map((item, index) => (
                                        <div style={index === i?.stats?.length - 1 ? {} : { borderBottom: '1px solid var(--aqua700)' }}>
                                            {item.stages?.cooked || '0'}
                                        </div>
                                    ))}
                                />
                                <GridTableItem
                                    style={{ padding: 0, borderBottom: '1px solid black' }}
                                    child={i?.stats.map((item, index) => (
                                        <div style={index === i?.stats?.length - 1 ? {} : { borderBottom: '1px solid var(--aqua700)' }}>
                                            {item.stages?.completepickup || '0'}
                                        </div>
                                    ))}
                                />
                                <GridTableItem
                                    style={{ padding: 0, borderBottom: '1px solid black' }}
                                    child={i?.stats.map((item, index) => (
                                        <div style={index === i?.stats?.length - 1 ? {} : { borderBottom: '1px solid var(--aqua700)' }}>
                                            {item.stages?.complete || '0'}
                                        </div>
                                    ))}
                                />
                            </tr>
                        ))}
                    </GridTable>
                </div>
            )}
            {!data.length && (
                <div className={'flex justify-center mt-2'}>
                    <MediumText style={{ textAlign: 'center' }}>Нет данных</MediumText>
                </div>
            )}
        </div>
    )
}
