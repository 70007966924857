import classNames from 'classnames'
import { AppMap, Divider, MediumText, RegularText } from 'src/components'
import { ELocationMarkerVariant, LocationMarker } from 'src/components/Map/LocationMarker'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { LatLng } from 'leaflet'
import axios from 'axios'
import { Polyline, Popup, useMap, useMapEvents } from 'react-leaflet'
import { useAtomValue, useSetAtom } from 'jotai/index'
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3'

import SocketServices from '../../services/SocketIO/socket'
import { Courier, LokaliApi, Order } from '../../services'
import {
    freeMapCouriersCountAtom,
    mapCouriersCityCoordinatesAtom,
    mapCouriersDropdownAtom,
    mapCouriersFiltersAtom,
    mapCouriersSelectedAtom,
    workingMapCouriersCountAtom,
} from '../../atoms/allCouriers/mapCouriersAtom'
import GroznyiCoordinates from '../../utils/groznyiCoordinates'
import { useWindowSize } from '../../hooks/useWindowResize'
import { getAddressFromObject } from '../../utils/getAddressFromObject'

type ActiveOrderType = Order & {
    order_id: string
    fromLat: number
    fromLong: number
    toLat: number
    toLong: number
    clientLat: number
    clientLong: number

    from_city: string
    from_street: string
    from_house: string
    from_corpus: string

    to_city: string
    to_street: string
    to_house: string
    to_corpus: string
}

type CourierCoords = {
    activeOrders: ActiveOrderType[]
    courier_id: string
    latitude: number
    longitude: number
}

interface Paths {
    [orderId: string]: LatLng[]
}

const colors = ['red', 'blue', 'green', 'yellow', 'purple']

const MapInner = ({ onMapClicked }: { onMapClicked: () => void }) => {
    const map = useMap()
    useMapEvents({
        click() {
            onMapClicked()
        },
    })
    const mapCouriersCityCoordinates = useAtomValue(mapCouriersCityCoordinatesAtom)
    const mapCouriersSelected = useAtomValue(mapCouriersSelectedAtom)

    useEffect(() => {
        if (mapCouriersCityCoordinates) {
            map.flyTo(mapCouriersCityCoordinates)
        }
    }, [mapCouriersCityCoordinates])

    useEffect(() => {
        if (mapCouriersSelected) {
            map.flyTo(mapCouriersSelected.coordinates)
        }
    }, [mapCouriersSelected])

    return <></>
}

const CourierLocation = ({ courier, orders }: { courier: Courier; orders: ActiveOrderType[] }) => (
    <Popup>
        <ul>
            <li>
                <MediumText>Таб. номер:</MediumText> <RegularText>{courier.item.tabNumber || '-'}</RegularText>
            </li>
            <li>
                <MediumText>Курьер:</MediumText> <RegularText>{courier.item.name || '-'}</RegularText>
            </li>
            <li>
                <MediumText>Телефон:</MediumText> <RegularText>{courier.item.phoneNumber || '-'}</RegularText>
            </li>
            <li>
                <MediumText>Тип курьера:</MediumText> <RegularText>{courier.groupObject?.title || '-'}</RegularText>
            </li>
            <li className='p-4'>
                <Divider />
            </li>
            <div style={{ maxHeight: '484px', overflowY: 'auto' }}>
                {orders?.length
                    ? orders.map(order => (
                          <tr key={order.order_id}>
                              <li>
                                  <MediumText>Заказ:</MediumText> <RegularText>{order.orderNumber || '-'}</RegularText>
                              </li>
                              <li>
                                  <MediumText>Телефон:</MediumText> <RegularText>{order.phoneNumber || '-'}</RegularText>
                              </li>
                              <li>
                                  <MediumText>Адрес откуда:</MediumText>{' '}
                                  <RegularText>
                                      {order.restAddress ||
                                          getAddressFromObject({
                                              city: order.from_city,
                                              street: order.from_street,
                                              house: order.from_house,
                                              corpus: order.from_corpus,
                                          }) ||
                                          '-'}
                                  </RegularText>
                              </li>
                              <li>
                                  <MediumText>Адрес куда:</MediumText>{' '}
                                  <RegularText>
                                      {order.deliveryAddress ||
                                          getAddressFromObject({
                                              city: order.to_city,
                                              street: order.to_street,
                                              house: order.to_house,
                                              corpus: order.to_corpus,
                                          }) ||
                                          '-'}
                                  </RegularText>
                              </li>
                              <li>
                                  <MediumText>Время доставки до:</MediumText>
                                  <RegularText>{order.deliveryTime || '-'}</RegularText>
                              </li>
                              <li>
                                  <MediumText>Время в пути:</MediumText>
                                  <RegularText>{order.timeOnWay || '-'}</RegularText>
                              </li>
                              <li>
                                  <MediumText>Комментарий:</MediumText> <RegularText>{order.comment || '-'}</RegularText>
                              </li>
                              <li className='p-4'>
                                  <Divider />
                              </li>
                          </tr>
                      ))
                    : null}
            </div>
        </ul>
    </Popup>
)

export const CourierMap = (): JSX.Element => {
    const mapCouriersFilters = useAtomValue(mapCouriersFiltersAtom)
    const setFreeMapCouriersCount = useSetAtom(freeMapCouriersCountAtom)
    const setWorkingMapCouriersCount = useSetAtom(workingMapCouriersCountAtom)
    const setMapCouriersDropdown = useSetAtom(mapCouriersDropdownAtom)

    const [positions, setPositions] = useState<CourierCoords[]>([])
    const [filteredPositions, setFilteredPositions] = useState<CourierCoords[]>([])
    const [activePosition, setActivePosition] = useState<CourierCoords>()

    const [paths, setPaths] = useState<Paths>({})
    const [courierInfo, setCourierInfo] = useState<Courier | object>({})
    // const [openedFromUrl, setOpenedFromUrl] = useState<boolean>(false);
    const pathsRef = useRef<Paths>({})
    const courierInfoRef = useRef<Courier | object>({})
    const loading = useRef(false)

    const [heatMapPoints, setHeatMapPoints] = useState<(string | number | null)[][]>([])

    const [width, height] = useWindowSize()

    const mapHeight = useMemo(() => (width ? height / Math.min(width / 2018, 1) - 162 : 0), [width, height])

    async function onMessage(newCoords: CourierCoords) {
        if (loading.current) {
            return
        }
        if (newCoords?.courier_id) {
            setPositions(prev => {
                const thisOldIndex = prev.findIndex(item => item.courier_id === newCoords.courier_id)
                if (thisOldIndex === -1) {
                    return [...prev, newCoords]
                }

                prev[thisOldIndex] = newCoords
                return prev
            })
        }

        try {
            loading.current = true
            if (!courierInfoRef.current[newCoords.courier_id]) {
                const info = await LokaliApi.getAllCouriers({ courier_id: newCoords.courier_id })
                if (info?.length) {
                    setCourierInfo(prev => ({ ...prev, [newCoords.courier_id]: info[0] }))
                }
            }

            if (!newCoords?.activeOrders?.length) return
            for (const order of newCoords.activeOrders) {
                if (pathsRef.current[order.order_id]) {
                    continue
                }

                if (!order.toLat) {
                    order.toLat = order.clientLat
                    order.toLong = order.clientLong
                }
                const { data } = await getPath(order)

                if (data?.routes?.length) {
                    setPaths(prev => ({ ...prev, [order.order_id]: data?.routes[0].geometry.coordinates }))
                }
            }
        } catch (e) {
            console.warn(e)
        } finally {
            loading.current = false
        }
    }

    useEffect(() => {
        pathsRef.current = paths
    }, [paths])

    useEffect(() => {
        courierInfoRef.current = courierInfo
    }, [courierInfo])

    useEffect(() => {
        const timeInterval = setInterval(() => {
            if (!positions?.length) {
                setFilteredPositions([])
                return
            }
            let filtered = [...positions]
            if (mapCouriersFilters?.groupId) {
                filtered = positions.filter(item => {
                    const courier: Courier = courierInfoRef.current[item.courier_id]
                    if (!courier) return false
                    return courier.item.group_id === mapCouriersFilters.groupId
                })
            }
            if (mapCouriersFilters?.busyType) {
                filtered = positions.filter(item => {
                    const courier: Courier = courierInfoRef.current[item.courier_id]
                    if (!courier) return false
                    return mapCouriersFilters.busyType === 'free' ? courier.activeOrderCount === 0 : courier.activeOrderCount !== 0
                })
            }

            setFilteredPositions(filtered)
        }, 10000)

        return () => {
            clearInterval(timeInterval)
        }
    }, [mapCouriersFilters, positions])

    useEffect(() => {
        const freeCount = filteredPositions.filter(item => {
            const courier: Courier = courierInfo[item.courier_id]
            if (!courier) return false
            return courier.activeOrderCount === 0
        }).length
        const workingCount = filteredPositions.filter(item => {
            const courier: Courier = courierInfo[item.courier_id]
            if (!courier) return false
            return courier.activeOrderCount !== 0
        }).length

        setFreeMapCouriersCount(freeCount)
        setWorkingMapCouriersCount(workingCount)

        setMapCouriersDropdown(
            filteredPositions.map(item => ({
                value: item.courier_id,
                label: courierInfoRef.current[item.courier_id]?.item?.sysName || '',
                coordinates: [item.latitude, item.longitude],
            })),
        )
    }, [filteredPositions, courierInfo])

    /*useEffect(() => {
    if (openedFromUrl) return;
    if (!positions?.length)  return;

    const courierId = window.location.search?.split('courier-id=')[1];
    if (!courierId) return;

    const courier = positions.find(item => item.courier_id === courierId);
    if (!courier) return;

    setActivePosition(courier);
    setOpenedFromUrl(true);
  }, [positions]);*/

    useEffect(() => {
        let socket: SocketServices
        ;(async () => {
            try {
                const data = await LokaliApi.couriersMapRequest()
                if (data?.message !== 'success') return
                socket = new SocketServices(onMessage)
            } catch (e) {}
        })()

        return () => (socket?.disconnect ? socket.disconnect() : (() => {})())
    }, [])

    useEffect(() => {
        ;(async () => {
            try {
                const data = await LokaliApi.getHeatmapPoints()
                if (!Object.keys(data)?.length) return
                const points: (string | number | null)[][] = []
                Object.keys(data).forEach(key => {
                    if (data[key][0]?.length) {
                        // eslint-disable-next-line max-nested-callbacks
                        data[key][0].forEach(item => {
                            points.push([item.lat, item.lon, '1'])
                        })
                    }
                })

                setHeatMapPoints(points)
            } catch (e) {}
        })()
    }, [])

    return (
        <div
            className={classNames('w-full border-t-primaryColor border-t-2 border-solid relative overflow-hidden')}
            style={{ height: mapHeight + 'px' }}>
            {mapHeight > 0 && (
                <AppMap mapHeight={'100%'} center={GroznyiCoordinates}>
                    {mapCouriersFilters?.showHeatMap ? (
                        <HeatmapLayer
                            fitBoundsOnLoad
                            // fitBoundsOnUpdate
                            points={heatMapPoints}
                            longitudeExtractor={m => m[1]}
                            latitudeExtractor={m => m[0]}
                            intensityExtractor={m => parseFloat(m[2])}
                            radius={60}
                            gradient={{ 0.1: 'blue', 0.5: 'yellow', 1.0: 'red' }}
                            blur={15}
                            // opacity={0.7}
                            // minOpacity={0.1}
                        />
                    ) : (
                        filteredPositions.map(position => (
                            <LocationMarker
                                key={position.courier_id}
                                position={[position.latitude, position.longitude]}
                                variant={
                                    courierInfo[position.courier_id]?.activeOrders !== 0
                                        ? ELocationMarkerVariant.RED
                                        : ELocationMarkerVariant.GREEN
                                }
                                eventHandlers={{
                                    click: () => setActivePosition(position),
                                }}>
                                {courierInfo[position.courier_id] ? (
                                    <CourierLocation courier={courierInfo[position.courier_id]} orders={position.activeOrders} />
                                ) : null}
                                {activePosition?.activeOrders?.length
                                    ? activePosition.activeOrders.map((order, orderIndex) =>
                                          paths[order.order_id] ? (
                                              <Polyline
                                                  key={order.order_id}
                                                  pathOptions={{
                                                      color: colors[(colors.length % (orderIndex + 1)) - 1],
                                                      stroke: true,
                                                  }}
                                                  positions={paths[order.order_id]}
                                              />
                                          ) : null,
                                      )
                                    : null}
                            </LocationMarker>
                        ))
                    )}

                    <MapInner
                        onMapClicked={() => {
                            setActivePosition(undefined)
                        }}
                    />
                </AppMap>
            )}
        </div>
    )
}

const getPath = async ({ fromLat, fromLong, toLat, toLong }: ActiveOrderType) => {
    const mapBoxKey = 'pk.eyJ1IjoibWFnb21lZDJycmdyb3VwIiwiYSI6ImNsendyZXhvNDBqejkya3M4ZW9pOTBkdXoifQ.yZAmFuCXoy9LYGK7C_ejSw'

    return await axios.get(
        // eslint-disable-next-line max-len
        `https://api.mapbox.com/directions/v5/mapbox/driving/${fromLat}%2C${fromLong}%3B${toLat}%2C${toLong}?alternatives=false&geometries=geojson&overview=simplified&steps=false&access_token=${mapBoxKey}`,
    )
}
